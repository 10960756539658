import NavBar from "../components/NavBar";
import Header from "../components/Header";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CollegeInfoContainer from "../components/CollegeInfoContainer";
import ActivitiesImages from "../components/ActivitiesImages";

const ActivitiesPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <NavBar />
      <Header text={"Activities"} />
      <ActivitiesImages />
      <CollegeInfoContainer />
    </div>
  );
};

export default ActivitiesPage;
