import React, { useState, useEffect } from "react";

const HomePageImages = () => {
  const images = [
    "home_page1.jpeg",
    "home_page2.jpeg",
    "home_page5.jpeg",
    // Add more image paths here
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="homepage-images">
      <div className="component">
        <h1>Gallery</h1>
      </div>
      <div className="carousel" style={{ padding: "150px" }}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index}`}
            style={{
              display: currentImageIndex === index ? "block" : "none",
              width: "40%",
              margin: "0 auto",
              border: "5px solid #2f3192",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default HomePageImages;
