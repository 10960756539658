import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AboutUsPage.module.css";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import AboutUsInfo from "../components/AboutUsInfo";
import CollegeInfoContainer from "../components/CollegeInfoContainer";

const AboutUsPage = () => {
  return (
    <div>
      <NavBar />
      <Header text={"About Us"} />
      <AboutUsInfo />
      <CollegeInfoContainer />
    </div>
  );
};

export default AboutUsPage;
