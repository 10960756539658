import { useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./NavBar.css";

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onActivitiesClick = useCallback(() => {
    navigate("/activities-page");
  }, [navigate]);

  const isCurrentPage = (path) => {
    return location.pathname === path;
  };

  const openProspectus = () => {
    window.open("/Prospectus_GWC.pdf", "_blank");
  };

  const openFacebookPage = () => {
    window.open("https://www.facebook.com/wapdawomencollege/", "_blank");
  };

  return (
    <div className="navBar">
      <img className="imageIcon" alt="" src="/image@2x.png" />
      <div className="navLinks">
        <Link
          className={isCurrentPage("/") ? "home active" : "home"}
          to="/"
          onClick={onHomeClick}
        >
          Home
        </Link>
        <Link
          className={isCurrentPage("/about-us-page") ? "about active" : "about"}
          to="/about-us-page"
        >
          About
        </Link>
        <Link
          className={
            isCurrentPage("/academics-page") ? "academics active" : "academics"
          }
          to="/academics-page"
        >
          Academics
        </Link>
        <Link
          className={
            isCurrentPage("/activities-page")
              ? "activities active"
              : "activities"
          }
          to="/activities-page"
          onClick={onActivitiesClick}
        >
          Activities
        </Link>
        <Link
          className={
            isCurrentPage("/contact-us") ? "contact active" : "contact"
          }
          to="/contact-us"
        >
          Contact
        </Link>

        <div className="download" onClick={openProspectus}>
          <Link className="prospectus">Prospectus</Link>
        </div>
      </div>
      <div className="frameIcons">
        <img
          className="frameIcon1"
          alt=""
          src="/frame1.svg"
          onClick={openFacebookPage}
        />
        <Link
          className={isCurrentPage("/login-page") ? "login active" : "login"}
          to="/login-page"
        >
          <img alt="" src="/login.svg" />
        </Link>
      </div>
    </div>
  );
};

export default NavBar;
