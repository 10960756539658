import React from "react";
import "./Subjects.css";

const Card = ({ image, title, caption }) => (
  <div className="card">
    <div className="image-container">
      <img src={image} alt={title} />
      <h3 className="card-title">{title}</h3>
    </div>
    <p className="card-caption">{caption}</p>
  </div>
);

const subjectData = {
  "Arts": [
    {
      image: "/Arabic.jpg",
      title: "Arabic",
      caption: "Delve into an ancient language rich in history and culture. Develop a deep understanding of Arabic literature and grammar.",
    },
    {
      image: "/Persian.jpeg",
      title: "Persian",
      caption: "Explore the language of poets and philosophers. Immerse yourself in Persian literature and cultural heritage.",
    },
    {
      image: "/English.jpg",
      title: "English",
      caption: "Master the universal language of communication. Gain comprehensive skills in English literature and language usage.",
    },
    {
      image: "/urdu.jpg",
      title: "Urdu",
      caption: "Celebrate the national language of Pakistan. Develop fluency in Urdu reading, writing, and literature.",
    },
    {
      image: "/punjabi.jpg",
      title: "Punjabi",
      caption: "Dive into the vibrant language of the Punjab region. Discover Punjabi literature, folklore, and linguistics.",
    },
    {
      image: "/Psychology.jpg",
      title: "Psychology",
      caption: "Uncover the mysteries of the human mind. Learn about cognitive processes, behavior analysis, and mental health.",
    },
    {
      image: "/HomeEconomics.jpeg",
      title: "Home Economics",
      caption: "Master the art of household management. Learn nutrition, food preparation, and family budgeting.",
    },
    {
      image: "/education.jpg",
      title: "Education",
      caption: "Understand the principles of teaching and learning. Dive into educational theory, policy, and practice.",
    },
    {
      image: "/history.jpg",
      title: "History",
      caption: "Travel through time with history. Understand the events that shaped the world we live in today.",
    },
    {
      image: "/IslamicEducation.jpg",
      title: "Islamic Education",
      caption: "Explore the tenets of Islam. Learn about the Quran, Hadith, and the principles of Islamic law.",
    },
  ],
  "Natural Sciences": [
    {
      image: "/Physics.jpg",
      title: "Physics",
      caption: "Investigate the laws of nature. Learn about energy, motion, and the structure of matter.",
    },
    {
      image: "/Biology.jpg",
      title: "Biology",
      caption: "Dive into the study of life and living organisms. Understand genetics, evolution, and biodiversity.",
    },
    {
      image: "/Chemistry.jpg",
      title: "Chemistry",
      caption: "Explore the elements and their interactions. Learn about organic, inorganic, and physical chemistry.",
    },
  ],
  "Quantitative Sciences": [
    {
      image: "/mathematics.jpg",
      title: "Mathematics",
      caption: "Unlock the language of the universe. Master calculus, algebra, geometry, and statistics.",
    },
    {
      image: "/statistics.jpg",
      title: "Statistics",
      caption: "Decode data and predict trends. Understand probability, statistical theory, and data analysis.",
    },
    {
      image: "/ComputerScience.jpg",
      title: "Computer Science",
      caption: "Navigate the digital world. Learn about algorithms, data structures, and computer programming.",
    },
  ],
};

const Subjects = () => (
  <div className="component">
    {Object.entries(subjectData).map(([section, subjects]) => (
      <section key={section}>
        <h1 id={section}>{section}</h1>
        <div className="card-container">
          {subjects.map((subject, index) => (
            <Card key={index} {...subject} />
          ))}
        </div>
      </section>
    ))}
  </div>
);

export default Subjects;
