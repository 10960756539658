import React from "react";
import { Link, useLocation } from "react-router-dom";

const Card = ({ image, title }) => {
  const location = useLocation();

  const isCurrentPage = (path) => {
    return location.pathname === path;
  };

  return (
    <div className="card">
      <Link
        className={
          isCurrentPage("/academics-page") ? "academics active" : "academics"
        }
        to="/academics-page"
      >
        <div className="image-container">
          <img src={image} alt={title} />
          <h3 className="card-title">{title}</h3>
        </div>
      </Link>
    </div>
  );
};

const CurriculumSection = () => {
  const CurriculumSubjects = [
    {
      image: "naturalsciences.jpg",
      title: "Natural Sciences",
    },
    {
      image: "datascience.jpg",
      title: "Quantitative sciences",
    },
    {
      image: "arts.jpg",
      title: "Arts",
    },
  ];

  return (
    <div className="component">
      <h1>Areas of Study</h1>
      <div className="card-container">
        {CurriculumSubjects.map((subject, index) => (
          <Card
            key={index}
            image={subject.image}
            title={subject.title}
          />
        ))}
      </div>
    </div>
  );
};

export default CurriculumSection;