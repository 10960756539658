import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import CollegeInfoContainer from "../components/CollegeInfoContainer";
import styles from "./ContactUs.module.css";

const ContactUs = () => {
  const navigate = useNavigate();

  const contactDetails = [
    { contact: "Email", detail: "gcwwapdatown@gmail.com" },
    { contact: "Fax", detail: "042-35181828" },
    { contact: "Office", detail: "042-35181828" },
    {
      contact: "Address Line 1",
      detail: "129 Street 3 WAPDA Town Block D 2 Wapda Town Phase 1",
    },
    { contact: "Address Line 2", detail: "Lahore, Punjab" },
    { contact: "Hours of Operation", detail: "8:00AM - 3:00PM" },
    // Add more contact members here if needed
  ];

  return (
    <div>
      <NavBar />
      <Header text={"Contact Us"} />
      <div
        className="contact-table"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
          height: "30%",
          margin: "5% auto",
          backgroundColor: "#ffffff",
        }}
      >
        <table style={{ borderCollapse: "collapse" }}>
          <tbody>
            {contactDetails.map((contact, index) => (
              <tr key={index}>
                <td style={{ padding: "20px", fontSize: "20px" }}>
                  {contact.contact}
                </td>
                <td
                  style={{
                    padding: "20px",
                    fontSize: "20px",
                    color: "#2f3192",
                  }}
                >
                  {contact.detail}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ width: "100%", height: "400px", margin: "0 auto" }}>
        <iframe
          title="Google Maps Embed"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3407.379979197149!2d74.2411293148145!3d31.420282781393702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39192e2e576aad8f%3A0xbab94f51e3d90f97!2s129%20St%203%2C%20Wapda%20Town%20Phase%201%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2sus!4v1625916602749!5m2!1sen!2sus"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
