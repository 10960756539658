import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase_setup/firebase";

const AboutUsInfo = () => {
  const [staffDetails, setStaffDetails] = useState([]);

  useEffect(() => {
    const fetchStaffDetails = async () => {
      const staffCollection = collection(firestore, "staffDetails");
      const staffSnapshot = await getDocs(staffCollection);
      let staffList = staffSnapshot.docs.map((doc) => doc.data());

      staffList.sort((a, b) => {
        if (a.position === "Principal") return -1;
        if (b.position === "Principal") return 1;
        if (a.position === "Vice Principal") return -1;
        if (b.position === "Vice Principal") return 1;
        return a.name.localeCompare(b.name); // this will sort the rest by name
      });

      setStaffDetails(staffList);
    };
    fetchStaffDetails();
  }, []);

  const notableDetails = [
    "Established in 2008",
    "26000+ graduates",
    "17 subjects offered",
    "7 degrees offered",
  ];

  const degreePrograms = [
    "F.A.",
    "F.Sc.",
    "B.A. Associate Degree",
    "B.Sc. Associate Degree",
    "I.C.S. Physics",
    "I.C.S. Economics",
    "I.Com. (2nd Shift)",
  ];

  return (
    <div>
      <div className="component">
        <h1>Mission Statement</h1>
      </div>
      <div
        className="mission-statement"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
          height: "30%",
          margin: "5% auto",
          backgroundColor: "#ffffff",
        }}
      >
        <p style={{ lineHeight: "2" }}>
          Government Graduate College for Women, Wapda Town Lahore, is dedicated
          to creating a vibrant learning environment that empowers women to
          become leaders, scholars, and innovators of the 21st century. Founded
          in 2008, our college is committed to providing high-quality
          undergraduate education in a range of disciplines, with a focus on
          critical thinking, intellectual growth, ethical understanding, and
          social awareness. We envision a future where every woman, irrespective
          of her socio-economic background, has the right to quality education
          and the ability to contribute meaningfully to society. Our mission is
          to foster an atmosphere of inclusivity, respect, and cultural
          diversity, thereby nurturing a community of globally-aware individuals
          who can navigate challenges and opportunities in an ever-evolving
          world. Our college strongly advocates for women's education as a
          fundamental right and a powerful force for societal transformation. We
          strive to bridge the gap between academia and the real world,
          instilling in our students a lifelong passion for learning, a strong
          sense of social responsibility, and the confidence to make a
          difference in their communities and beyond. Through a holistic
          approach to education, encompassing academic excellence, leadership
          development, personal growth, and community service, we are committed
          to preparing our students to be the thinkers, doers, and change-makers
          of tomorrow. We believe that by inspiring and empowering our students
          today, we can shape a better, more equitable future for all.
        </p>
      </div>
      <div className="component">
        <h1>Degree Programs</h1>
      </div>
      <div
        className="degree-programs"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
          margin: "5% auto",
          backgroundColor: "#ffffff",
          padding: "2%",
        }}
      >
        <ul style={{ lineHeight: "2", fontSize: "22px" }}>
          {degreePrograms.map((program, index) => (
            <li key={index}>{program}</li>
          ))}
        </ul>
      </div>

      <div className="component">
        <h1>History and Notable Details</h1>
      </div>
      <div
        className="notable-details"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
          height: "30%",
          margin: "5% auto",
          backgroundColor: "#ffffff",
        }}
      >
        <ol style={{ lineHeight: "2", fontSize: "22px" }}>
          {notableDetails.map((detail, index) => (
            <li key={index}>{detail}</li>
          ))}
        </ol>
      </div>
      <div className="component">
        <h1>Staff Details</h1>
      </div>
      <div
        className="staff-table"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
          height: "30%",
          margin: "5% auto",
          backgroundColor: "#ffffff",
        }}
      >
        <table style={{ borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  padding: "20px",
                  fontWeight: "bold",
                  fontSize: "20px",
                  borderBottom: "1px solid #2f3192",
                }}
              >
                Name
              </th>
              <th
                style={{
                  padding: "20px",
                  fontWeight: "bold",
                  fontSize: "20px",
                  color: "#2f3192",
                  borderBottom: "1px solid black",
                }}
              >
                Position
              </th>
            </tr>
          </thead>
          <tbody>
            {staffDetails.map((staff, index) => (
              <tr key={index}>
                <td style={{ padding: "20px", fontSize: "20px" }}>
                  {staff.name}
                </td>
                <td
                  style={{
                    padding: "20px",
                    fontSize: "20px",
                    color: "#2f3192",
                  }}
                >
                  {staff.position}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AboutUsInfo;
