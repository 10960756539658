import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CurriculumSection from "../components/CurriculumSection";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import CollegeInfoContainer from "../components/CollegeInfoContainer";
import HomePageImages from "../components/HomePageImages";
import HomePageNews from "../components/HomePageNews";
const HomePage = () => {
  return (
    <div>
      <NavBar />
      <Header
        text={"Government Graduate College for Women Wapda Town"}
        backgroundImage="/rectangle@2x.png"
      />
      <HomePageNews />
      <CurriculumSection />
      <HomePageImages />

      <CollegeInfoContainer />
    </div>
  );
};

export default HomePage;
