import NavBar from "../components/NavBar";
import Header from "../components/Header";
import CollegeInfoContainer from "../components/CollegeInfoContainer";
import Subjects from "../components/Subjects";
import "./AcademicsPage.css";

const AcademicsPage = () => {
  return (
    <div>
      <NavBar />
      <Header text="Academics" />
      <Subjects />
      <naturalSciences />
      <CollegeInfoContainer />
    </div>
  );
};

export default AcademicsPage;
