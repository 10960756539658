import React, { useState, useEffect } from "react";

const ActivitiesImages = () => {
  const images = [
    {
      path: "SportsDay.jpg",
      title: "Sports Day",
      caption:
        "An integral part of our college culture, Sports Day is a day dedicated to celebrating athleticism, teamwork, and the spirit of healthy competition. This event encourages students to display their physical prowess and sportsmanship across a variety of sports and games.",
    },
    {
      path: "SpringFestival.jpg",
      title: "Spring Festival",
      caption:
        "This event signifies the arrival of spring and the celebration of new beginnings. The Spring Festival is a medley of vibrant colors, traditional music, dance performances, and food stalls, embodying the rich cultural diversity of our student community.",
    },
    {
      path: "LiteraryFestival.jpeg",
      title: "Literary and Art Festival",
      caption:
        "At the intersection of creativity and imagination lies our Literary and Art Festival. This event offers a platform for students to showcase their talents in various art forms, including painting, writing, poetry, and drama, fostering a culture of creativity and innovation on campus.",
    },
    // Add more image paths, titles, and captions here
    {
      path: "treeday.jpg",
      title: "Plant for Pakistan",
      caption:
        "The Plant for Pakistan campaign is an initiative to promote environmental sustainability and conservation. This event encourages students to plant trees and participate in discussions on environmental issues, fostering a sense of responsibility towards the planet.",
    },
    {
      path: "treeday2.jpg",
      title: "Plant for Pakistan",
      caption:
        "The Plant for Pakistan campaign is an initiative to promote environmental sustainability and conservation. This event encourages students to plant trees and participate in discussions on environmental issues, fostering a sense of responsibility towards the planet.",
    },
  ];


  const videos = [
    "9pxbjoVlhb8",
    "dM5QKOmDolI"
    // Add more video IDs here
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length, videos.length]);

  return (
    <div className="homepage-images">
      <div className="component">
        <h1>About Activities</h1>
      </div>
      <div
        className="mission-statement"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
          height: "30%",
          margin: "5% auto",
          backgroundColor: "#ffffff",
        }}
      >
        <p style={{ lineHeight: "2", fontSize: "16px" }}>
          Government Degree College for Women, Wapda Town Lahore, hosts a series
          of enriching annual events aimed at promoting holistic development and
          community engagement. These events include Sports Day, emphasizing
          physical fitness and teamwork; the vibrant Spring Festival celebrating
          cultural diversity; and the exciting Annual Fun Fair that offers
          entertainment and socializing opportunities.
          <br />
          <br />
          Furthermore, the college conducts regular seminars to enhance critical
          thinking and broad perspective on various topics. An important part of
          the yearly calendar is the Dengue Awareness campaign, reflecting the
          institution's commitment to community health and safety. The Annual
          Prize Distribution event recognizes and rewards student excellence in
          academics and extracurricular activities.
          <br />
          <br />
          Lastly, the Literary Festival is held as a celebration of literature,
          offering students a platform to express their creativity and interact
          with notable literary figures. Together, these events create an
          engaging, well-rounded educational experience for every student.
        </p>
      </div>
      <div className="component">
        <h1>Gallery</h1>
      </div>
      <div
        className="carousel"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "150px",
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              display: currentImageIndex === index ? "block" : "none",
              textAlign: "center",
            }}
          >
            <h2>{image.title}</h2>
            <img
              src={image.path}
              alt={image.title}
              style={{
                width: "35%",
                margin: "0,auto",
                border: "5px solid #2f3192",
              }}
            />

            <p style={{ color: "#2f3192" }}>{image.caption}</p>
          </div>
        ))}
      </div>
      <div className="component">
        <h1>Videos</h1>
      </div>
      <div
        className="video-carousel"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "150px",
        }}
      >
        {videos.map((videoId, index) => (
          <div
            key={index}
            style={{
              display: currentVideoIndex === index ? "block" : "none",
              textAlign: "center",
            }}
          >
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        ))}
      </div>

    </div>
  );
};

export default ActivitiesImages;
