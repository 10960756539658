import React from "react";
import "./HeadingSection.css";

const HeadingSection = ({ text, backgroundImage }) => {
  const sectionStyle = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : "none",
    color: backgroundImage ? "White" : "var(--color-white)", // Change text color when background image is present
  };

  return (
    <div className="headingSection" style={sectionStyle}>
      <div className="textContainer">{text}</div>
    </div>
  );
};

export default HeadingSection;
