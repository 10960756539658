import { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";

import NavBar from "../components/NavBar";
import Header from "../components/Header";
import LoginForm from "../components/LoginForm";
import CollegeInfoContainer from "../components/CollegeInfoContainer";

const LoginPage = () => {
  return (
    <div>
      <NavBar />
      <Header text={"Login"} />

      <LoginForm />

      <CollegeInfoContainer />
    </div>
  );
};

export default LoginPage;
