import { useMemo } from "react";
const CollegeInfoContainer = ({ propTop, propLeft }) => {
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        backgroundColor: "#2f3192",
        color: "white",
        textAlign: "center",
        paddingBottom: "10px",
      }}
    >
      <div style={{ paddingTop: "10px" }}>
        <h2>Govt. Graduate College for Women, Wapda Town</h2>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ marginRight: "10%", width: "40%" }}>
          <h3>129 Street 3, WAPDA Town Block D 2 Wapda Town Phase 1 </h3>
        </div>
        <div style={{ width: "40%" }}>
          <h3>Office: 042-35181828</h3>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ marginRight: "10%", width: "40%" }}>
          <h3>Lahore, Punjab</h3>
        </div>
        <div style={{ width: "40%" }}>
          <h3>Hours of Operation: 8:00AM - 5:00PM</h3>
        </div>
      </div>
      <p>Odin Solutions © 2023</p>
    </div>
  );
};

export default CollegeInfoContainer;
