import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase_setup/firebase";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  query,
} from "firebase/firestore";
import { firestore } from "../firebase_setup/firebase";

const LoginForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [news, setNews] = useState([]);
  const [newTitle, setNewTitle] = useState("");
  const [newCaption, setNewCaption] = useState("");
  const [staffDetails, setStaffDetails] = useState([]);
  const [newName, setNewName] = useState(""); // New state

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        setIsLoggedIn(true); // Set isLoggedIn to true
        console.log("User logged in");
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  const addNews = async (e) => {
    e.preventDefault();
    await addDoc(collection(firestore, "News"), {
      title: newTitle,
      caption: newCaption,
    });
    setNewTitle("");
    setNewCaption("");
    const newsQuery = query(collection(firestore, "News"));
    const newsSnapshot = await getDocs(newsQuery);
    setNews(newsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };

  const deleteNews = async (id) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this item? This action cannot be undone."
    );
    if (confirmation) {
      await deleteDoc(doc(firestore, "News", id));
      const newsQuery = query(collection(firestore, "News"));
      const newsSnapshot = await getDocs(newsQuery);
      setNews(newsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      const fetchNews = async () => {
        const newsQuery = query(collection(firestore, "News"));
        const newsSnapshot = await getDocs(newsQuery);
        setNews(
          newsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );

        const staffQuery = query(collection(firestore, "staffDetails"));
        const staffSnapshot = await getDocs(staffQuery);
        setStaffDetails(
          staffSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      };

      fetchNews();
    }
  }, [isLoggedIn]);

  const updateName = async (id) => {
    const staffRef = doc(firestore, "staffDetails", id);
    await updateDoc(staffRef, { name: newName });
    const staffQuery = query(collection(firestore, "staffDetails"));
    const staffSnapshot = await getDocs(staffQuery);
    setStaffDetails(
      staffSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
    setNewName(""); // Reset newName after update
  };

  // other functions...

  return (
    <>
      <main>
        <section>
          <div>
            <form
              onSubmit={onLogin}
              style={{
                padding: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {isLoggedIn && (
                <h2 style={{ color: "green" }}>Login Successful</h2>
              )}
              <div style={{ marginBottom: "1rem", width: "50%" }}>
                <label
                  htmlFor="email-address"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Enter Email Address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  required
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ marginBottom: "1rem", width: "100%" }}
                />
              </div>

              <div style={{ marginBottom: "1rem", width: "50%" }}>
                <label
                  htmlFor="password"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Enter Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ marginBottom: "1rem", width: "100%" }}
                />
              </div>

              <div>
                <button type="submit" style={{ padding: "0.5rem 1rem" }}>
                  Login
                </button>
              </div>
            </form>
            {isLoggedIn && (
              <>
                <div className="component">
                  <h1>News</h1>
                </div>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: "20px",
                          fontWeight: "bold",
                          fontSize: "20px",
                          borderBottom: "1px solid #2f3192",
                          textAlign: "left",
                        }}
                      >
                        Title
                      </th>
                      <th
                        style={{
                          padding: "20px",
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: "#2f3192",
                          borderBottom: "1px solid #2f3192",
                          textAlign: "left",
                        }}
                      >
                        Caption
                      </th>
                      <th
                        style={{
                          padding: "20px",
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: "#2f3192",
                          borderBottom: "1px solid #2f3192",
                          textAlign: "left",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {news.map(({ id, title, caption }) => (
                      <tr key={id}>
                        <td
                          style={{
                            padding: "20px",
                            fontSize: "20px",
                            borderBottom: "1px solid #2f3192",
                          }}
                        >
                          {title}
                        </td>
                        <td
                          style={{
                            padding: "20px",
                            fontSize: "20px",
                            color: "#2f3192",
                            borderBottom: "1px solid #2f3192",
                          }}
                        >
                          {caption}
                        </td>
                        <td
                          style={{
                            padding: "20px",
                            fontSize: "20px",
                            color: "#2f3192",
                            borderBottom: "1px solid #2f3192",
                          }}
                        >
                          <button onClick={() => deleteNews(id)}>Delete</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <form
                  onSubmit={addNews}
                  style={{
                    marginTop: "5%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginBottom: "1rem", width: "50%" }}>
                    <label
                      htmlFor="newTitle"
                      style={{ display: "block", marginBottom: "0.5rem" }}
                    >
                      New Event's Title
                    </label>
                    <input
                      id="newTitle"
                      name="newTitle"
                      required
                      placeholder="Title"
                      value={newTitle}
                      onChange={(e) => setNewTitle(e.target.value)}
                      style={{ marginBottom: "1rem", width: "100%" }}
                    />
                  </div>

                  <div style={{ marginBottom: "1rem", width: "50%" }}>
                    <label
                      htmlFor="newCaption"
                      style={{ display: "block", marginBottom: "0.5rem" }}
                    >
                      New Event's Caption
                    </label>
                    <input
                      id="newCaption"
                      name="newCaption"
                      required
                      placeholder="Caption"
                      value={newCaption}
                      onChange={(e) => setNewCaption(e.target.value)}
                      style={{ marginBottom: "1rem", width: "100%" }}
                    />
                  </div>

                  <div>
                    <button type="submit" style={{ padding: "0.5rem 1rem" }}>
                      Add News
                    </button>
                  </div>
                </form>
                <div className="component">
                  <h1>Staff Details</h1>
                </div>
                <table
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    paddingBottom: "5%",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: "20px",
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: "black",
                          borderBottom: "1px solid #2f3192",
                          textAlign: "left",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          padding: "20px",
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: "#2f3192",
                          borderBottom: "1px solid #2f3192",
                          textAlign: "left",
                        }}
                      >
                        Position
                      </th>
                      <th
                        style={{
                          padding: "20px",
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: "#2f3192",
                          borderBottom: "1px solid #2f3192",
                          textAlign: "left",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {staffDetails.map(({ id, name, position }) => (
                      <tr key={id}>
                        <td
                          style={{
                            padding: "20px",
                            fontSize: "20px",
                            borderBottom: "1px solid #2f3192",
                          }}
                        >
                          {name}
                        </td>
                        <td
                          style={{
                            padding: "20px",
                            fontSize: "20px",
                            borderBottom: "1px solid #2f3192",
                          }}
                        >
                          {position}
                        </td>
                        <td
                          style={{
                            padding: "20px",
                            fontSize: "20px",
                            borderBottom: "1px solid #2f3192",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="New name"
                            onChange={(e) => setNewName(e.target.value)}
                          />
                          <button onClick={() => updateName(id)}>
                            Update Name
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br />
                <br />
              </>
            )}
          </div>
        </section>
      </main>
    </>
  );
};

export default LoginForm;
