import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase_setup/firebase";

const HomePageNews = () => {
  const [news, setNews] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchNews = async () => {
      const newsCollection = collection(firestore, "News");
      const newsSnapshot = await getDocs(newsCollection);
      const newsList = newsSnapshot.docs.map((doc) => doc.data());
      setNews(newsList);
    };

    fetchNews();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % news.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [news.length]);

  return (
    <div className="homepage-images">
      <div className="component">
        <h1>News and Events</h1>
      </div>
      <div
        className="carousel"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "50px",
        }}
      >
        {news.map((story, index) => (
          <div
            key={index}
            style={{
              display: currentImageIndex === index ? "block" : "none",
              textAlign: "center",
            }}
          >
            <h2>{story.title}</h2>
            <p style={{ color: "#2f3192" }}>{story.caption}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePageNews;
